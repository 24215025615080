<template>
<div>
     <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

    <div class="row">
        <div class="col-md-12">
            <CCard>
                <CCardBody>
                    <CForm>
                        <CRow>
                            <CCol sm="12" class="d-none d-md-block">
                                <router-link
                                    :to="{
                                        name: 'Edit Farmer User Profile',
                                        params: { profile },
                                    }"
                                >
                                    <!-- <router-link to="/editFarmerUserProfile"> -->
                                    <CButton
                                        color="primary"
                                        class="float-right mt-2 mr-2"
                                    >
                                        <CIcon name="cil-pencil" />
                                    </CButton>
                                </router-link>

                                <div class="d-flex mt-2">
                                    <img
                                        :src="profile.picture"
                                        class="avatar ml-3 mb-3"
                                    />
                                </div>
                            </CCol>
                        </CRow>


                        <CRow class="mt-3 ml-1 mt-xl-0 mt-lg-0 mt-md-0">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                   First name
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.first_name}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="col-md-5 col-lg-5 col-xl-4 ">
                                    Last name
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.last_name}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                   Category
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.name}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                    Email
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.email}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>


                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="col-md-5 col-lg-5 col-xl-4 ">
                                    Contact
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.phone}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="col-md-5 col-lg-5 col-xl-4 ">
                                    City
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.city}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                    State
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.state}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <!-- <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                    Country
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.country}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow> -->

                        <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                            <div class="row">
                                <div class="asterisk col-md-5 col-lg-5 col-xl-4 ">
                                    Postal code
                                </div>
                                <div class="col-md-7 col-lg-7 col-xl-8">
                                    {{profile.postal}}
                                </div>
                            </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow>

                        <!-- <CRow class="mt-3 ml-1">
                            <CCol class="col-6">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-xl-4">
                                        Status
                                    </div>
                                    <div class="col-md-7 col-lg-7 col-xl-8">
                                        <template v-if="profile.is_active==true">
                                            <p>Active</p>
                                        </template>  
                                        <template v-else>
                                            <p>Inactive</p>
                                        </template>  
                                    </div>
                                </div>
                            </CCol>
                            <CCol class="col-6"> </CCol>
                        </CRow> -->
                    </CForm>
                </CCardBody>
            </CCard>
        </div>
    </div>
    
</div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const profileUrl = URL + `${"profile/farmeruser/profile/"}`;

export default {
    name: "FarmerUserProfile",
    data() {
        return {
            errorMessage: "",
            checkError: false,
            profile: {
                id: null,
                full_name: "",
                first_name: "",
                last_name: "",
                name: "",
                email: "",
                city: "",
                state: "",
                country: "",
                postal: "",
                phone: "",
                picture: "",
                is_active: "",
            },
        };
    },
    methods: {
        fetchData() {
            this.axios
                .get(profileUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.profile = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },

    
    beforeMount() {
        this.fetchData();
    },
};
</script>